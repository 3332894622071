/* eslint-disable @next/next/no-img-element */

import { MouseEvent, useContext, useEffect, useRef, useState } from 'react';
import { GiftProduct } from 'charlie-workflows/types';
import Link from 'next/link';
import { ChatProvider } from '../../../chat/providers/chat.provider';
import { useModal } from '@/src/services/modal';
import { AffiliateLinkModal } from '../../../chat/components/affiliate-link/components/affiliate-link-modal/affiliate-link-modal.component';
import { AffiliateLink } from '../../../chat/components/affiliate-link/affiliate-link.component';
import styles from './gift-product-card.module.scss';

export const GiftProductCard: React.FC<GiftProduct['payload']> = (props) => {
  const { imageURL, productName, price, details, purchaseLink, isAffiliate } =
    props;
  const { onCardClick } = useContext(ChatProvider);

  const affiliateLinksContainerRef = useRef<HTMLDivElement>(null);
  const [isAffiliateLinkPulse, setIsAffiliateLinkPulse] = useState(true);

  const isEmpty = !imageURL && !productName && !productName && !price;

  const { open: openAffiliateModal } = useModal(AffiliateLinkModal);

  const handleAffiliateClick = (e: MouseEvent) => {
    e.stopPropagation();
    openAffiliateModal({});
  };

  // useEffect(() => {
  //   const cb = () => {
  //     setIsAffiliateLinkPulse(false);
  //   };

  //   const ref = affiliateLinksContainerRef.current;

  //   ref?.addEventListener('mouseenter', cb);

  //   return () => {
  //     ref?.removeEventListener('mouseenter', cb);
  //   };
  // }, [setIsAffiliateLinkPulse, affiliateLinksContainerRef]);

  return (
    <div className={styles.container}>
      {isAffiliate ? (
        <div
          className={styles.affiliateLinkContainer}
          ref={affiliateLinksContainerRef}
        >
          {/* {isAffiliateLinkPulse ? (
            <span className={styles.pulseButton}></span>
          ) : null} */}
          <AffiliateLink
            className={styles.affiliateLink}
            onClick={handleAffiliateClick}
          ></AffiliateLink>
        </div>
      ) : null}
      <Link
        href={purchaseLink || ''}
        target="_blank"
        id="product-card"
        className={`${isEmpty ? 'emptyProduct' : ''} productCardItemContainer`}
        onClick={(e) => {
          onCardClick(props);

          if (isEmpty) {
            e.preventDefault();
          }
        }}
      >
        <div className="imageContainer">
          <img
            className="image"
            src={imageURL || '/assets/logo-light.png'} // use placeholder if imageURL is not available
            alt="Product image preview"
            decoding="async"
            // loading="lazy" // disabled due to iOS
          ></img>
        </div>
        <div className="contentContainer">
          {isEmpty ? <div className="skeletonOverlay"></div> : null}

          <div className="title">
            {isEmpty ? (
              <div className="skeletonContainer">
                <div className="skeletonContent">&nbsp;</div>
              </div>
            ) : (
              <span title={productName}>
                {isEmpty ? '&nbsp;' : productName}
              </span>
            )}
          </div>
          <div className="infoContainer">
            {isEmpty ? (
              <div className="skeletonContainer">
                <div className="skeletonContent">&nbsp;</div>
              </div>
            ) : price ? (
              <div className="price">{`$${price?.toFixed(0)}`}</div>
            ) : null}
          </div>
          <div className="descriptionContainer">
            {isEmpty ? (
              <div className="skeletonContainer">
                <div className="skeletonContent">&nbsp;</div>
              </div>
            ) : details.shortDescription ? (
              <span title={details.shortDescription} className="description">
                {details.shortDescription || ''}
              </span>
            ) : null}
          </div>
        </div>
      </Link>
    </div>
  );
};
