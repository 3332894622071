import { useCallback, useEffect, useRef, useState } from 'react';
import { RecipientEntity } from 'charlie-workflows/contracts';
import { SessionEntity } from 'charlie-workflows/contracts/sessions';
import { GiftProduct, RecipientOccasion } from 'charlie-workflows/types';
import { HiCheck, HiPencil, HiX } from 'react-icons/hi';
import { format } from 'date-fns';

import {
  BaseModalProps,
  useConfirmModal,
  useSlideModal,
} from '@/src/services/modal';
import { EditableItem } from '@/src/shared/ui-kit/components/editable-item/editable-item.component';
import styles from './recipient-view-modal.module.scss';
import { Loader } from '@/src/shared/ui-kit/components/loader';
import { useInjectable, useSubscription } from '@/src/services';
import {
  IRecipientsApiService,
  RecipientsApiServiceContainerType,
} from '@/src/services/recipients-api';
import { PreviewProductCard } from '@/src/shared/ui-kit/components/preview-product-card';
import { Modal } from '@/src/shared/ui-kit/components/modal';
import { Input } from '@/src/shared/ui-kit/components/input';
import {
  IOccasionModalProps,
  OccasionsModal,
} from '../occasions-modal/occasions-modal.component';
import { ExtendedRecipientInformation } from '@/pages/api/recipients/[id]';
import { auditTime, noop } from 'rxjs';
import { cutOffset } from '@/src/utils/common.utils';
import {
  ISessionsService,
  SessionsServiceContainerType,
} from '@/src/services/sessions';
import { useRouter } from 'next/router';

export interface IRecipientViewModalProps extends BaseModalProps {
  recipient: ExtendedRecipientInformation;
  onStartNewChat: () => void;
  onAddNewOccasion: () => void;
  onNameUpdate: (name: string) => void;
  onDelete: () => void;
}

export const RecipientViewModal: React.FC<IRecipientViewModalProps> = ({
  onClose,
  onStartNewChat,
  recipient,
  onAddNewOccasion,
  onNameUpdate,
  onDelete,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const router = useRouter();
  const sessionId = Array.isArray(router.query.id)
    ? router.query.id[0]
    : router.query.id;
  const [name, setName] = useState(recipient.name || 'Unnamed recipient');
  const [isNameEditing, setIsNameEditing] = useState(false);
  const { open: openRemovingConfirm, close: closeRemovingConfirm } =
    useConfirmModal();

  const { deleteSession, updateSession, sessions$ } =
    useInjectable<ISessionsService>(SessionsServiceContainerType);

  const sessions = useSubscription(sessions$);
  let sessionsForRecipient = sessions?.filter(
    (session) => session.recipientId === recipient.id,
  );

  return (
    <div
      className={styles.container}
      onClick={(event) => event.stopPropagation()}
    >
      <Modal.Body className={styles.body}>
        <div className={styles.bodyContent}>
          <div className={styles.headerRow}>
            <HiX onClick={onClose} className={styles.icon} />
            <Input
              ref={inputRef}
              disabled={!isNameEditing}
              className={`${styles.editableItem} ${
                !isNameEditing ? styles.stand : ''
              }`}
              onChange={(event) => setName(event.target.value)}
              value={name}
              suffix={
                isNameEditing ? (
                  <HiCheck
                    className={`${styles.icon} ${styles.actionIcon}`}
                    onClick={() => {
                      if (name && name.length) {
                        onNameUpdate(name);
                      }
                      setIsNameEditing(false);
                    }}
                  />
                ) : (
                  <HiPencil
                    className={`${styles.icon} ${styles.actionIcon}`}
                    onClick={() => {
                      setIsNameEditing(true);
                    }}
                  />
                )
              }
            />
          </div>
          <div>
            <h2>Saved searches</h2>
            {sessionsForRecipient && sessionsForRecipient.length ? (
              <ul className={styles.searchesList}>
                {sessionsForRecipient.map((session) => (
                  <EditableItem
                    key={session.id}
                    id={session.id}
                    value={session.name || 'Unnamed session'}
                    mode="menu"
                    onSave={(value) => {
                      updateSession(session.id, value).subscribe(noop);
                    }}
                    onRemove={() =>
                      openRemovingConfirm({
                        confirmText: 'Delete session',
                        subMessage: 'This cannot be undone',
                        cancelText: 'Keep it',
                        message:
                          'Are you sure you want to permanently delete this search?',
                        onConfirm: () => {
                          deleteSession(session.id).subscribe(noop);
                          closeRemovingConfirm();

                          if (sessionId === session.id) {
                            open('/', '_self');
                          }
                        },
                      })
                    }
                  />
                ))}
              </ul>
            ) : (
              <span>No searches for this recipient yet</span>
            )}
          </div>
          <div>
            <div className={styles.row}>
              <h2>
                Saved occasions (
                {recipient.occasions ? recipient.occasions.length : 0})
              </h2>
              <button
                className={styles.addNewButton}
                onClick={onAddNewOccasion}
              >
                Add new
              </button>
            </div>
            {recipient.occasions?.length ? (
              <ul>
                {(recipient.occasions || []).map((occasion) => (
                  <li key={occasion.name} className={styles.occasionName}>
                    {occasion.name || 'Unnamed occasion'}{' '}
                    <span>
                      {Array.isArray(occasion.date)
                        ? `${format(
                            Number(cutOffset(occasion.date[0])),
                            'MMM dd',
                          )} - ${format(
                            Number(cutOffset(occasion.date[1])),
                            'MMM dd',
                          )}`
                        : format(Number(cutOffset(occasion.date)), 'MMM dd')}
                    </span>
                  </li>
                ))}
              </ul>
            ) : (
              <span>No saved occasions for this recipient yet</span>
            )}
          </div>
          <div>
            <h2>Saved gift ideas ({recipient.savedProducts.length})</h2>
            {recipient.savedProducts.length ? (
              <ul className={styles.productsList}>
                {recipient.savedProducts.map((product) => (
                  <div
                    className={styles.previewProductCardWrapper}
                    key={product.payload.productId}
                  >
                    <PreviewProductCard
                      className={styles.previewProductCard}
                      product={product}
                    />
                  </div>
                ))}
              </ul>
            ) : (
              <span>No saved ideas for this recipient yet</span>
            )}
          </div>
          <div>
            <button className={styles.deleteButton} onClick={onDelete}>
              Delete this recipient
            </button>
          </div>
        </div>

        <div className={styles.actionBar}>
          <button
            className="primary"
            onClick={() => {
              onStartNewChat();
              onClose?.();
            }}
          >
            Start new gift search for {recipient.name || 'this recipient'}
          </button>
        </div>
      </Modal.Body>
    </div>
  );
};

// SMART component

export interface ISmartRecipientViewModalProps
  extends Omit<
    IRecipientViewModalProps,
    'recipient' | 'onAddNewOccasion' | 'onNameUpdate' | 'onDelete'
  > {
  recipientId: string;
  onUpdateRecipient: (recipient?: RecipientEntity) => void;
}

export const SmartRecipientViewModal: React.FC<
  ISmartRecipientViewModalProps
> = ({ recipientId, onUpdateRecipient, onClose, ...props }) => {
  const [recipient, setRecipient] =
    useState<ExtendedRecipientInformation | null>(null);
  const [isRecipientLoading, setIsRecipientLoading] = useState(false);

  const {
    getRecipientById,
    update,
    delete: deleteRecipient,
  } = useInjectable<IRecipientsApiService>(RecipientsApiServiceContainerType);

  const updateRecipient = useCallback(() => {
    setIsRecipientLoading(true);
    getRecipientById(recipientId).subscribe({
      next: (data) => {
        setRecipient(data);
        setIsRecipientLoading(false);
      },
      error: () => {
        setRecipient(null);
        setIsRecipientLoading(false);
      },
    });
  }, [getRecipientById, recipientId]);

  const { open } = useSlideModal<IOccasionModalProps>(
    ({ onClose, ...props }) => (
      <OccasionsModal
        {...props}
        onSave={(occasions) => {
          update(recipientId, {
            occasions: occasions.reduce<RecipientOccasion[]>(
              (acc, { date, isActive, name }) =>
                isActive && date ? [...acc, { date, name }] : acc,
              [],
            ),
          }).subscribe(() => {
            updateRecipient();

            if (onClose) {
              onClose();
            }
          });
        }}
        onSkip={onClose}
        onClose={onClose}
      />
    ),
  );

  const { open: openConfirm, close: closeConfirm } = useConfirmModal();

  useEffect(() => {
    updateRecipient();
  }, [updateRecipient]);

  if (isRecipientLoading) {
    return (
      <Modal.Body>
        <Loader />
      </Modal.Body>
    );
  }

  if (recipient) {
    return (
      <RecipientViewModal
        {...props}
        recipient={recipient}
        onAddNewOccasion={() => {
          open({
            texts: {
              acceptButtonName: 'Save',
              cancel: 'Cancel',
            },
            occasions: recipient.occasions,
          });
        }}
        onNameUpdate={(name) => {
          update(recipientId, { name }).subscribe(onUpdateRecipient);
        }}
        onClose={onClose}
        onDelete={() => {
          openConfirm({
            onClose: closeConfirm,
            confirmText: 'Delete',
            cancelText: 'Cancel',
            message: 'Do you want to delete this recipient?',
            subMessage:
              'This will permanently delete the profile and all saved ideas associated with it',
            onConfirm: () => {
              closeConfirm();
              deleteRecipient(recipient.id)
                .pipe(auditTime(1000))
                .subscribe(() => {
                  onUpdateRecipient();
                  onClose?.();
                });
            },
          });
        }}
      />
    );
  }

  return null;
};
