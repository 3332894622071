import { BaseModalProps } from '@/src/services/modal';
import { Datepicker } from '@/src/shared/ui-kit/components/datepicker/datepicker.component';
import { Modal } from '@/src/shared/ui-kit/components/modal';
import { Switcher } from '@/src/shared/ui-kit/components/switcher/switcher.component';
import { RecipientOccasion } from 'charlie-workflows/types';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import styles from './occasions-modal.module.scss';

export interface OccasionsFormValue extends Omit<RecipientOccasion, 'date'> {
  isActive: boolean;
  editable?: boolean;
  date?: string | [string, string];
}

export interface OccasionFormValues {
  occasions: OccasionsFormValue[];
}

interface IOccasionModalTexts {
  acceptButtonName?: string;
  subheader?: string;
  header?: string;
  cancel?: string;
}

interface IOccasionModalDefaultValues {
  birthday?: boolean;
  christmas?: boolean;
  hanukkah?: boolean;
  fathersDay?: boolean;
  anniversary?: boolean;
  mothersDay?: boolean;
  valentinesDay?: boolean;
}

export interface IOccasionModalProps extends BaseModalProps {
  occasions?: RecipientOccasion[];
  texts?: IOccasionModalTexts;
  defaultValues?: IOccasionModalDefaultValues;
  onSave?: (occasions: OccasionsFormValue[]) => void;
  onSkip?: () => void;
}

const mergeOccasions = (
  occasions: RecipientOccasion[] = [],
  defaultValue: OccasionsFormValue[] = [],
) => {
  const occasionsMap = (occasions || []).reduce((acc, item) => {
    return {
      ...acc,
      [item.name]: {
        ...item,
        isActive: true,
      },
    };
  }, {} as Record<string, OccasionsFormValue>);

  return (defaultValue || []).map((item) => {
    return {
      ...item,
      ...occasionsMap[item.name],
    };
  });
};

export const OccasionsModal: React.FC<IOccasionModalProps> = ({
  occasions,
  defaultValues,
  texts,
  onSave,
  onSkip,
}) => {
  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<OccasionFormValues>({
    defaultValues: {
      occasions: mergeOccasions(occasions, [
        {
          name: 'Birthday',
          isActive: Boolean(defaultValues?.birthday),
        },
        {
          name: 'Christmas',
          date: '2023-12-25',
          isActive: Boolean(defaultValues?.christmas),
          editable: false,
        },
        {
          name: 'Hanukkah',
          date: ['2023-12-07', '2023-12-15'],
          isActive: Boolean(defaultValues?.hanukkah),
          editable: false,
        },
        {
          name: "Father's Day",
          date: '2023-06-16',
          isActive: Boolean(defaultValues?.fathersDay),
          editable: false,
        },
        {
          name: 'Anniversary',
          isActive: Boolean(defaultValues?.anniversary),
        },
        {
          name: "Mother's Day",
          date: '2024-05-12',
          isActive: Boolean(defaultValues?.mothersDay),
          editable: false,
        },
        {
          name: "Valentine's Day",
          date: '2024-02-14',
          isActive: Boolean(defaultValues?.valentinesDay),
          editable: false,
        },
      ]),
    },
    criteriaMode: 'all',
  });

  const { fields } = useFieldArray({
    control,
    name: 'occasions',
    rules: {
      validate: (value) =>
        Boolean(
          value
            .filter((item) => Boolean(item.isActive))
            .every((item) => item.date),
        ),
    },
  });

  return (
    <form
      className={styles.container}
      onSubmit={handleSubmit((form) => {
        if (isValid) {
          onSave?.(form.occasions);
        }
      })}
      onClick={(event) => event.stopPropagation()}
    >
      <Modal.Body className={styles.modalBody}>
        <div className={styles.content}>
          <h3 className={styles.title}>
            {texts?.header || 'Add new occasion'}
          </h3>

          <h2 className={styles.subTitle}>
            {texts?.subheader ||
              'I can send you ideas ahead of future occasions! (I’ll never spam you)'}
          </h2>
          <ul className={styles.list}>
            {fields.map((item, index) => {
              return (
                <li key={item.id} className={styles.item}>
                  <Controller
                    control={control}
                    name={`occasions.${index}`}
                    render={({
                      field: { onChange, value, onBlur, ref },
                      fieldState: { invalid, isDirty, isTouched, error },
                    }) => {
                      const { isActive, name, date, editable = true } = value;

                      return (
                        <div className={`${styles.formItem}`}>
                          <div className={styles.switcher}>
                            <Switcher
                              checked={isActive}
                              onChange={(switchValue) => {
                                onChange({ ...value, isActive: switchValue });
                              }}
                            />

                            <span
                              className={`${styles.name} ${
                                !isActive ? styles.nameDisabled : ''
                              }`}
                            >
                              {name}
                            </span>
                          </div>

                          <Datepicker
                            disabled={!value.isActive || !editable}
                            value={date}
                            className={`${
                              !isActive ? styles.datepickerDisabled : ''
                            }`}
                            // error={isTouched && !Boolean(value.date)}
                            onBlur={onBlur}
                            onChange={(datePickerValue) => {
                              onChange({ ...value, date: datePickerValue });
                            }}
                          />
                        </div>
                      );
                    }}
                  />
                </li>
              );
            })}
          </ul>
        </div>
        <div className={styles.actions}>
          {/* <div
            className={`${styles.error} ${
              shouldValidate && isValid ? styles.isHidden : ''
            }`}
          >
            Make sure that all fields are filled
          </div> */}
          <button
            type="submit"
            className={`primary ${styles.addButton} ${
              !isValid ? styles.disabled : ''
            }`}
            disabled={!isValid}
          >
            {texts?.acceptButtonName || 'Add this occasion'}
          </button>

          {onSkip ? (
            <button
              className={`${styles.skipButton}`}
              onClick={() => onSkip?.()}
            >
              {texts?.cancel || 'Skip and save profile'}
            </button>
          ) : null}
        </div>
      </Modal.Body>
    </form>
  );
};
