import { useState } from 'react';
import { useRouter } from 'next/router';
import { useInjectable } from '@/src/services';
import {
  ICreateChatArgs,
  IMessagesApiService,
  MessagesApiServiceContainerType,
} from '@/src/services/messages-api';
import {
  IStorageService,
  KnownStorageKeys,
  StorageServiceContainerType,
} from '@/src/services/storage';
import { useFeatureFlagVariantKey } from 'posthog-js/react';

export interface UseCreateChatHook {
  createChat: (
    initialMessage: string | undefined,
    data: ICreateChatArgs,
    file?: File,
  ) => void;
  isChatCreationLoading: boolean;
}

export const useCreateChat = (
  successCallback = () => {},
): UseCreateChatHook => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);

  const promptFeatureFlagVariant = useFeatureFlagVariantKey(
    'recommendation-prompt-v2',
  );
  const streamingProductCardCountFlagVariant = useFeatureFlagVariantKey(
    'product-cards-count',
  );
  const recommendationsPromptEngineVersionFeatureFlagVariant =
    useFeatureFlagVariantKey('recommendations-engine-experiment');
  const randomPromptExperimentFeatureFlagVariant = useFeatureFlagVariantKey(
    'prompt-a-b-experiment',
  );

  const { retrieve, store } = useInjectable<IStorageService>(
    StorageServiceContainerType,
  );
  const { createChat } = useInjectable<IMessagesApiService>(
    MessagesApiServiceContainerType,
  );

  const handleMessageSubmit = (
    messageInput: string | undefined,
    args: ICreateChatArgs,
  ) => {
    setIsLoading(true);

    createChat({
      promptExperience: promptFeatureFlagVariant
        ? String(promptFeatureFlagVariant)
        : undefined,
      streamingProductCardCountExperience: streamingProductCardCountFlagVariant
        ? String(streamingProductCardCountFlagVariant)
        : undefined,
      recommendationsPromptEngineVersion:
        recommendationsPromptEngineVersionFeatureFlagVariant
          ? String(recommendationsPromptEngineVersionFeatureFlagVariant)
          : undefined,
      randomPromptExperimentFeatureFlagVariant:
        randomPromptExperimentFeatureFlagVariant
          ? String(randomPromptExperimentFeatureFlagVariant)
          : undefined,
      ...args,
    }).subscribe({
      next: ({ id }) => {
        if (!id) {
          return;
        }

        successCallback?.();

        router.push(`/chat/${id}`, undefined, { shallow: true });

        if (messageInput && messageInput.length) {
          const existingInitialMessages = retrieve<Record<string, string[]>>(
            KnownStorageKeys.Messages,
          );
          store(KnownStorageKeys.Messages, {
            ...existingInitialMessages,
            [id]: [messageInput],
          });
        }

        // setIsLoading(false);
      },
      error: () => {
        // setIsLoading(false);
      },
    });
  };

  return {
    createChat: handleMessageSubmit,
    isChatCreationLoading: isLoading,
  };
};
